import React from "react"
import { Link } from "gatsby"
import { ChevronLeftIcon } from "@heroicons/react/24/solid"

const OdeslanoPage = ({ pageContext }) => {
  return (
    <>
      <main className="pb-8">
        <div className="mx-auto w-full">
          <div className="mx-auto max-w-3xl w-full  py-16 px-8 ">
            <div className=" pt-20 prose prose-sm">
              <h1>Objednávka odeslána</h1>
              <div className="mb-10">
                <p>Děkujeme za vaši objednávku.</p>{" "}
              </div>
            </div>
            <div>
              <div className="pt-8">
                <div>
                  <Link
                    to={"/produkty"}
                    className="bg-gray-500 mb-20  text-white font-bold py-4 px-4  rounded hover:bg-gray-600 inline-flex pr-10 items-center"
                  >
                    <ChevronLeftIcon className="w-7 h-7 mr-5" />
                    <span>Zpět na úvod</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default OdeslanoPage
